import * as React from 'react';
import { withLayout } from '../components/Layout';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import { Constrain, Prose } from '../components/ui';
import Helmet from 'react-helmet';
import { BannerAdvert } from '../components/BannerAdvert/BannerAdvert.component';

const PrivacyPage = () => (
    <>
        <Helmet>
            <title>Your data and privacy</title>

            <meta
                name="description"
                content="The We Make Waves data and privacy policy"
            />
        </Helmet>

        <FixedMasthead />

        <BannerAdvert />

        <Constrain type="narrow">
            <Prose>
                <h1>Privacy statement</h1>
                <p>
                    This privacy policy explains how we use the
                    personal data we collect from you when you use our website.
                </p>
                <h3>How do we collect your data?</h3>
                
                <p>Our Sites features our contact information which invites you to submit general enquiries via phone or email. We collect the personal data you submit to us when making your enquiry.</p>

                <p>
                    You directly provide us with most of the data we collect. We
                    collect data and process data when you:
                </p>
                <ul>
                    <li>Book a call through using our calendar</li>
                    <li>Book a call through our contact form</li>
                    <li>Call or email us directly</li>
                </ul>
                <h3>How will we use your data?</h3>
                <p>
                    We use and use your personal information for the following purposes:
                </p>

                <ul>
                    <li>To fulfil our supply of goods and/or services to clients</li>
                    <li>To manage our client relationships</li>
                    <li>To handle inquiries</li>
                    <li>To promote and market our services</li>
                    <li>Comply with any applicable law, court order, other judicial process, or the requirements of a regulator; and use as otherwise required or permitted by law.</li>
                </ul>

                <p>Where you are a prospective client, we may use personal information that you voluntarily submit to us to prepare proposals for your purchase of our Services, to contact you, or otherwise to manage or administer our relationship with you.</p>

                <h4>Fulfilment of services</h4>

                <p>We collect and store personal information that you voluntarily submit to us by email or phone. We use the personal data you provide to us so that we can fulfil the supply of Services you have engaged us to provide.</p>
                
                <p>Sometimes, we may be granted access to certain user data (including personal data) when we help our clients build, maintain and develop their own products, applications or software.</p>

                <p>We might share your personal information with the following categories of third parties:</p>

                <ul>
                    <li>Web hosting, optimisation and management companies - who assist us with the hosting, operation, optimisation and maintenance of our Site and the administration of your client account with us.</li>
                    <li>Events - any applicable third party hosting or facilitating the event.</li>
                    <li>Storage - third party cloud storage providers that store our data on secure servers.</li>
                </ul>

                <p>We do not share user data (or other personal data) that we might inadvertently process as part of helping our clients build, maintain and develop their own products, applications or software.</p>

                <h3>How do we store your data?</h3>

                <p>
                    We Make Waves securely stores your data on Netlify web
                    servers based in Germany.
                </p>
                <p>
                    We are committed to doing all that we can to keep your data
                    secure. We also make sure that any third parties that we
                    deal with keep all personal data they process on our behalf
                    secure.
                </p>
                <p>
                    We Make Waves will keep your contact info for minimum of 1
                    year and a maximum of 7 years. Once this time period has
                    expired, we will delete your data.
                </p>

                <h3>Marketing</h3>

                <p>
                    We Make Waves would like to send you information about
                    products and services of ours that we think you might like.
                </p>
                <p>
                    If you have agreed to receive marketing, you may always opt
                    out at a later date.
                </p>
                <p>
                    You have the right at any time to stop We Make Waves from
                    contacting you for marketing purposes.
                </p>
                <p>
                    If you no longer wish to be contacted for marketing
                    purposes, please contact us via email at{' '}
                    <a href="mailto: together@wemakewaves.digital">
                        together@wemakewaves.digital
                    </a>
                    .
                </p>
                <h3>What are your data protection rights?</h3>
                <p>
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following:
                </p>
                <p>
                    <strong>The right to access</strong> – You have the right to
                    request for copies of your personal data. We may charge you
                    a small fee for this service.
                </p>
                <p>
                    <strong>The right to rectification</strong> – You have the
                    right to request that we correct any information you believe
                    is inaccurate. You also have the right to request We Make
                    Waves complete the information you believe is incomplete.
                </p>
                <p>
                    <strong>The right to erasure</strong> – You have the right
                    to request that we erase your personal data, under certain
                    conditions.
                </p>
                <p>
                    <strong>The right to restrict processing</strong> – You have
                    the right to request that we restrict the processing of your
                    personal data, under certain conditions.
                </p>
                <p>
                    <strong>The right to object to processing</strong> – You
                    have the right to object to our processing of your personal
                    data, under certain conditions.
                </p>
                <p>
                    <strong>The right to data portability</strong> – You have
                    the right to request that we transfer the data that we have
                    collected to another organisation, or directly to you, under
                    certain conditions.
                </p>
                <p>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact our data controller James Dowell on our email:{' '}
                    <a href="mailto: together@wemakewaves.digital">
                        together@wemakewaves.digital
                    </a>
                </p>
                <p>
                    Call us at: <a href="tel:020 3744 3554">020 3744 3554</a>
                </p>
                <h3>Cookies</h3>
                <p>
                    Cookies are text files placed on your computer to collect
                    standard Internet log information and visitor behavior
                    information. When you visit our websites, we only use
                    cookies that are necessary for the functionality of the
                    website. You can read more about the{' '}
                    <a href="/cookies">purpose of these cookies</a>.
                </p>
                <h4>How to manage cookies</h4>
                <p>
                    You can set your browser not to accept cookies in your{' '}
                    <a href="https://www.wikihow.com/Disable-Cookies" rel="nofollow noreferrer">browser settings</a>. 
                    However, in a few cases, some of our website
                    features may not function as a result.
                </p>
                <h3>Privacy policies of other websites</h3>
                <p>
                    Our website contains links to other websites. Our privacy
                    policy applies only to our website, so if you click on a
                    link to another website, you should read their privacy
                    policy.
                </p>
                <h3>Changes to our privacy policy</h3>
                <p>
                    We Make Waves keeps its privacy policy under regular review
                    and places any updates on this web page. This privacy policy
                    was last updated on 02 February 2021.
                </p>
                <h3>How to contact us</h3>
                <p>
                    If you have any questions about our privacy policy, the data
                    we hold on you, or you would like to exercise one of your
                    data protection rights, please do not hesitate to contact
                    us.
                </p>
                <p>
                    Email us at:{' '}
                    <a href="mailto: together@wemakewaves.digital">
                        together@wemakewaves.digital
                    </a>
                </p>
                <p>
                    Call us at: <a href="tel:020 3744 3554">020 3744 3554</a>
                </p>

                <h3>How to contact the appropriate authority</h3>
                <p>
                    Should you wish to report a complaint or if you feel that
                    Our Company has not addressed your concern in a satisfactory
                    manner, you may contact the Information Commissioner’s
                    Office.
                </p>

                <p>Call: <a href="tel: 0303 123 1113">0303 123 1113</a></p>

                <p>Website: <a href="https://ico.org.uk/">https://ico.org.uk/</a></p>
            </Prose>
        </Constrain>
    </>
);

export default withLayout(PrivacyPage);
