import React from 'react';
import {
    MastheadWrapper,
    MastheadBar,
    MASTHEAD_LOGO_HEIGHTS
} from './Masthead.component';
import { pixelToRem, breakpoints } from '../../utilities';
import styled from '../../styled-components';
import { StylelessLink } from '../ui';
import Navigation from '../Navigation/Navigation.component';

const Spacer = styled.div`
    height: 100px;

    @media (max-width: ${breakpoints.m}px), (max-height: 900px) {
        height: 70px;
    }
`;

const Logo = styled.svg`
    min-width: 65px;
    height: ${pixelToRem(MASTHEAD_LOGO_HEIGHTS.m)};
    fill: ${({ theme }) => theme.colors.deepBlue.color};
    display: block;

    @media (max-width: ${breakpoints.m}px), (max-height: 900px) {
        height: ${pixelToRem(MASTHEAD_LOGO_HEIGHTS.s)};
    }
`;

interface IFixedMastheadState {
    showShadow: boolean;
}

class UnstyledFixedMasthead extends React.Component<
    wmw.IBaseStyledComponentProps,
    IFixedMastheadState
> {
    public state: IFixedMastheadState = {
        showShadow: false
    };

    public componentDidMount() {
        this.isAtTop();
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public render() {
        const { className } = this.props;

        return (
            <div className={className}>
                <Navigation reverse={true} />

                <Spacer />

                <MastheadWrapper
                    style={{
                        boxShadow: `${
                            this.state.showShadow
                                ? '0 1px 1px 0 rgba(157,157,157,0.2)'
                                : '0 0 0 0 rgba(157,157,157,0.2)'
                        }`
                    }}
                >
                    <MastheadBar>
                        <StylelessLink to="/" aria-label="Link to homepage">
                            <Logo
                                viewBox="0 0 530 208"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <g>
                                    <polygon points="529.76 0.19 505.59 0.19 442.27 174.16 435.36 155.18 491.78 0.19 467.61 0.19 423.28 121.98 416.37 103.01 453.8 0.19 429.63 0.19 404.29 69.81 378.95 0.19 378.95 0.19 378.95 0.19 361.69 0.19 359.96 4.94 352.37 25.81 342.01 54.27 334.41 75.14 324.05 103.6 291.12 194.08 286.29 207.36 295.95 207.36 303.55 207.36 332.68 127.31 358.02 196.92 361.82 207.36 378.39 207.36 382.54 207.36 380.46 201.67 368.38 168.46 343.04 98.85 350.64 77.98 375.98 147.59 388.06 180.8 394.97 199.77 397.73 207.36 416.37 207.36 418.45 207.36 417.41 204.51 405.32 171.31 398.42 152.34 386.33 119.14 361 49.52 368.59 28.65 393.93 98.26 406.02 131.47 412.92 150.44 425.01 183.64 431.91 202.62 433.64 207.36 454.35 207.36 454.35 207.36 454.35 207.36" />
                                    <polygon points="326.12 52.37 333.72 31.5 344.08 3.04 345.11 0.19 343.04 0.19 327.85 0.19 325.09 7.78 317.49 28.65 307.13 57.11 274.2 147.59 267.3 166.57 258.32 191.23 252.45 207.36 264.19 207.36 269.71 207.36 275.93 190.28 282.83 171.31 315.77 80.83" />
                                    <path d="M265.91,124.83 L298.84,34.35 L309.2,5.89 L311.27,0.2 L307.13,0.2 L294.01,0.2 L290.21,10.64 L257.28,101.12 L220.55,0.19 L212.95,0.19 L206.73,0.19 L209.84,8.73 L250.37,120.08 L241.39,144.74 L200.87,33.4 L195.69,19.17 L188.78,0.2 L183.26,0.2 L174.97,0.2 L179.11,11.58 L188.78,38.14 L193.96,52.37 L234.49,163.72 L225.51,188.38 L184.99,77.03 L179.81,62.8 L170.14,36.24 L164.96,22.01 L157.02,0.19 L153.57,0.19 L143.21,0.19 L102.68,111.54 L62.15,0.19 L55.24,0.19 L99.22,121.03 L104.4,135.26 L114.07,161.82 L119.25,176.05 L128.92,202.61 L130.65,207.35 L137.55,207.35 L178.08,96 L218.61,207.35 L232.42,207.35 L235.87,207.35 L243.12,187.43 L250.03,168.46 L259.01,143.8 L265.91,124.83 Z M107.86,125.78 L148.39,14.43 L158.06,40.99 L117.53,152.34 L107.86,125.78 Z M132.37,193.13 L122.7,166.57 L163.23,55.22 L172.9,81.78 L132.37,193.13 Z" />
                                    <polygon points="103.72 190.28 94.05 163.72 88.87 149.49 34.53 0.19 27.62 0.19 85.42 158.98 90.6 173.21 100.26 199.77 103.03 207.36 107.86 207.36 109.93 207.36 108.89 204.51" />
                                    <polygon points="75.06 187.44 6.91 0.19 0 0.19 71.6 196.92 75.4 207.36 78.16 207.36 82.31 207.36 80.24 201.67" />
                                </g>
                            </Logo>
                        </StylelessLink>
                    </MastheadBar>
                </MastheadWrapper>
            </div>
        );
    }

    private update = () => {
        const showShadow = !this.isAtTop();

        if (this.state.showShadow !== showShadow) {
            this.setState({ showShadow });
        }
    };

    private isAtTop = () => {
        return window.scrollY === 0;
    };

    private handleScroll = (e: any) => {
        window.requestAnimationFrame(this.update);
    };
}

export const FixedMasthead = styled(UnstyledFixedMasthead)`
    ${MastheadWrapper} {
        transition: box-shadow 300ms;

        html.no-js & {
            position: fixed;
        }
    }

    ${MastheadBar} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
